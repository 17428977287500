import Actions from "modules/actions/revenue-code-actions";
import RevenueCode from "store/models/RevenueCode";
import { capitalize, isEqual } from "lodash";

const actions = new Actions();

export default {
  name: "AddRevenueCodeModal",
  props: {
    isViewing: {
      type: Boolean,
      required: true,
    },
    isRevenueCodeModalVisible: {
      type: Boolean,
      required: true,
    },
    defaultValues: {
      type: String,
      required: true,
      default: "",
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
    cancelButtonText: String,
    submitButtonText: String,
  },
  data() {
    return {
      revenueCodeModel: RevenueCode,
      revenueCodeTypes: [],
      menu1: false,
      menu2: false,
      date1: "",
      date2: "",
      revenueCode: {
        id: null,
        codeType: "",
        code: "",
        description: "",
        helpInfo: "",
        glCode: "",
        validityStartDate: "",
        validityEndDate: "",
      },
      isEditing: false,
      isDisabled: false,
      isFetchingCodeTypes: false,
      validateProgress: {
        isValidating: false,
        progress: 0,
      },
      confirmChangesModal: {
        isVisible: false,
        message: "Are you sure you want to save the updates?",
      },
      rules: {
        required: (val) => {
          if (typeof val === "object" && val !== null) {
            return (
              (val && val.id && val.id !== null) || "This field is required."
            );
          }
          return (
            (val !== null && val.length !== 0) || "This field is required."
          );
        },
        validateRevenueCode: async (val) => {
          const result = await this.isRevenueCodeExisting(val);
          return result;
        },
        validDate: (val) =>
          !val || this.isValidDate(val) || "Invalid date format.",
        validValidityEndDate: (val, validityStartDate) => {
          if (!val) return true;
          const validityEndDate = new Date(val);
          const effectDate = new Date(validityStartDate);
          if (validityStartDate === null || !validityStartDate.length)
            return true;
          return (
            validityEndDate >= effectDate ||
            "Termination date must not be less than the effective date."
          );
        },
      },
    };
  },
  computed: {
    isFormDisabled() {
      const { isValidating } = this.validateProgress;
      return this.isLoading
        || (this.isViewing && !this.isEditing)
        || this.isFetchingCodeTypes
        || isValidating
        || this.isDisabled;
    },
    isActiveForm() {
      return this.isEditing || !this.isViewing;
    },
    isCodeFieldDisabled() {
      return this.isFormDisabled || this.isEditing || !!this.defaultValues;
    },
    codeTypeOptions() {
      if (this.isFetchingCodeTypes) {
        return [{
          label: "Fetching Code Types...",
          disabled: true,
        }];
      }
      return this.revenueCodeTypes;
    }
  },
  watch: {
    isRevenueCodeModalVisible(newVal) {
      this.menu1 = false;
      this.menu2 = false;
      if (!newVal) {
        this.revenueCode = {
          codeType: "",
          code: "",
          description: "",
          helpInfo: "",
          glCode: "",
          validityStartDate: "",
          validityEndDate: "",
        };
        this.date1 = "";
        this.date2 = "";
        this.isEditing = false;
      }
    },
    defaultValues(newVal) {
      if (this.isViewing && newVal) {
        const formValues = JSON.parse(newVal);
        this.revenueCode = formValues;
        if (formValues.codeType && !this.revenueCodeTypes.length) {
          this.revenueCodeTypes = [{
            label: this.revenueCode.codeType.split("_").map(i => capitalize(i)).join(' '),
            value: this.revenueCode.codeType,
            isDefault: true,
          }];
        }
      }
    },
  },
  methods: {
    async getCodeTypes() {
      try {
        const fetchedCodeTypes = this.revenueCodeTypes.filter(i => !i.isDefault);
        if (!this.isFetchingCodeTypes && !fetchedCodeTypes.length) {
          this.isFetchingCodeTypes = true;
          const { enumValues } = await actions.getEnumValues("VbsRevenueCodeTypeEnum");
          this.isFetchingCodeTypes = false;
          if (enumValues) {
            this.revenueCodeTypes = enumValues.map(({ name }) => ({
              label: name.split("_").map(i => capitalize(i)).join(' '),
              value: name,
            }));
            return;
          } 
          this.showNotifyMessage({
            type: "danger",
            message: "Unable to fetch data.",
          });
        }
      } catch (err) {
        this.isFetchingCodeTypes = false;
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async addRevenueCode() {
      try {
        const {
          codeType,
          code,
          description,
          helpInfo,
          glCode,
          validityStartDate,
          validityEndDate,
        } = this.revenueCode;
        
        await actions.createRevenueCode({
          input: {
              type: "VbsRevenueCodeInput!",
              value: {
                codeType,
                code,
                description,
                helpInfo,
                glCode: glCode || null,
                validityStartDate:  this.$options.filters.getTimeDate(
                  validityStartDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  this.getTimezoneOffset({
                    date: validityStartDate,
                  })
                ).utc,
                validityEndDate: validityEndDate
                  ? this.$options.filters.getTimeDate(
                      validityEndDate,
                      "YYYY-MM-DD HH:mm:ss",
                      false,
                      null,
                      {
                        days: 1,
                        ...this.getTimezoneOffset({
                          date: validityEndDate,
                          minusSeconds: 1,
                        }),
                      }
                    ).utc
                  : "",
              },
              unincludeToFields: true,
          },
          limitData: ["errors", "success"],
        });
        this.$emit("refetchRevenueCodes");
        this.$emit("toggleFormModal", false);
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Record has been saved successfully!",
          type: "success",
        });
      } catch (error) {
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Problem has occurred while saving data.",
          type: "danger",
        });
      }
    },
    async updateRevenueCode() {
      this.confirmChangesModal.isVisible = false;
      try {
        const {
          codeType,
          description,
          helpInfo,
          id,
          validityStartDate,
          validityEndDate,
        } = this.revenueCode;
        const hasDateChanges = this.valueHasChanged('validityStartDate', true) || this.valueHasChanged('validityEndDate', true);
        const params = {
          variables: {
            id: {
              type: "UUID!",
              value: id,
              unincludeToFields: true,
            },
          },
          queries: {},
          collectionQueries: [],
        };
        if (this.valueHasChanged('codeType')) {
          params.collectionQueries.push({
            updateCodeType: {
              codeType: {
                type: "VbsRevenueCodeTypeEnum!",
                value: codeType || "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }
        if (this.valueHasChanged('description')) {
          params.collectionQueries.push({
            updateDescription: {
              description: {
                type: "String!",
                value: description || "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }
        if (this.valueHasChanged('helpInfo')) {
          params.collectionQueries.push({
            updateHelpInfo: {
              helpInfo: {
                type: "String!",
                value: helpInfo || "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }
        if (hasDateChanges) {
          params.collectionQueries.push({
            updateValidityDates: {
              validityStartDate: {
                type: "String",
                value: this.$options.filters.getTimeDate(
                  validityStartDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  this.getTimezoneOffset({
                    date: validityStartDate,
                  }),
                ).utc,
                unincludeToFields: true,
              },
              validityEndDate: {
                type: "String",
                value: validityEndDate ? this.$options.filters.getTimeDate(
                  validityEndDate,
                  "YYYY-MM-DD HH:mm:ss",
                  false,
                  null,
                  {
                    days: 1,
                    ...this.getTimezoneOffset({
                      date: validityEndDate,
                      minusSeconds: 1,
                    }),
                  }
                ).utc : "",
                unincludeToFields: true,
              },
              mutationReturn: ["success", "errors"],
            },
          });
        }
        await actions.updateRevenueCodeDetails(params);
        this.$emit("refetchRevenueCodes");
        this.$emit("toggleFormModal", false);
        this.isDisabled = false;
        this.isEditing = false;
        this.showNotifyMessage({
          message: "Revenue Code Details changes were saved.",
          type: "success",
        });
      } catch (error) {
        this.isDisabled = false;
        this.showNotifyMessage({
          message: "Problem has occurred while updating data.",
          type: "danger",
        });
      }
    },
    async isRevenueCodeExisting(code) {
      if ([null, undefined, ""].includes(code))
        return "This field is required.";
      if (this.isViewing && code === JSON.parse(this.defaultValues).code)
        return true;
      const results = await actions.getAllRevenueCodes({
        filter: {
          code,
          enum_patternMatch: "EXACT",
          archive: true,
          logicalOperator: "AND",
        },
        limitData: ["code"],
        shouldNotReturnId: true,
      });
      return results.length > 0 ? "Revenue Code already exists." : true;
    },
    checkForChanges() {
      if (!this.isEditing) return;
      const hasChanges = !isEqual(JSON.parse(this.defaultValues), this.revenueCode);
      return hasChanges;
    },
    valueHasChanged(field, isDate) {
      const defaultValue = JSON.parse(this.defaultValues)[field];
      if (defaultValue && isDate) {
        return !(defaultValue.split(' ')[0] === this.revenueCode[field]);
      }
      return !(defaultValue === this.revenueCode[field]);
    },
    async onCodeChange(value) {
      if (typeof value === "string") {
        const codeInput = value.toUpperCase();
        this.revenueCode.code = await codeInput.trim();
      }
    },
    parseDate(date) {
      if (!this.isValidDate(date)) return "";
      const [month, day, year] = date.split("/");
      return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
    },
    formatDate(date) {
      if (!date) return null;
      const [year, month, day] = date.split("-");
      return `${month}/${day}/${year}`;
    },
    closeDateModal(dataModel, menu, date) {
      this.revenueCode[dataModel] = this.formatDate(date);
      this[menu] = false;
    },
    onCancel() {
      if (!this.isActiveForm) {
        this.isEditing = false;
        this.$emit("toggleFormModal", false);
        return;
      }
      const fields = [
        "codeType",
        "code",
        "description",
        "helpInfo",
        "glCode",
        "validityStartDate",
        "validityEndDate",
      ];
      let hasChanges = false;
      if (this.isEditing) {
        const defaultValues = JSON.parse(this.defaultValues);
        fields.forEach((field) => {
          if (defaultValues[field] !== this.revenueCode[field]) {
            hasChanges = true;
            return;
          }
        });
      } else {
        fields.forEach((field) => {
          if (this.revenueCode[field]) {
            hasChanges = true;
            return;
          }
        });
      }
      if (hasChanges) {
        this.$emit("toggleDiscardModal", true);
        return;
      }
      this.isEditing = false;
      this.$emit("toggleFormModal", false);
    },
    async validateForm() {
      if (this.isFormDisabled) return;
      this.validateProgress.isValidating = true;
      const isFormValid = await this.$refs.addRevenueForm.validateAsync();
      if (isFormValid) {
        this.isDisabled = true;
      }
      this.validateProgress = {
        isValidating: false,
        progress: 100,
      };
      return isFormValid;
    },
    async onSubmit() {
      const isFormValid = await this.validateForm();
      if (!isFormValid) return;
      if (this.isEditing) {
        const hasChanges = this.checkForChanges();
        if (!hasChanges) {
          this.showNotifyMessage({
            message: "No changes were made.",
            type: "danger",
          });
          this.isDisabled = false;
          return;
        }
        this.confirmChangesModal.isVisible = true;
      } else {
        this.addRevenueCode();
      }
    },
  },
};
