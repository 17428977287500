import Actions from "modules/actions/revenue-code-actions";
import AddRevenueCodeModal from "./modals/AddRevenueCodeModal";
import NotificationModal from "components/PromptModal";
import RevenueCode from "store/models/RevenueCode";
import { capitalize } from "lodash";

const actions = new Actions();

export default {
  name: "RevenueCodes",
  components: {
    AddRevenueCodeModal,
    NotificationModal,
  },
  data() {
    return {
      isLoading: false,
      orderBy: {
        codeType: "orderByCodeType",
        code: "orderByCode",
        description: "orderByCodeDescription",
        helpInfo: "orderByHelpInfo",
        glCode: "orderByGLCode",
        status: "orderByValidityStatus",
        validityStartDate: "orderByValidityStartDate",
        validityEndDate: "orderByValidityEndDate",
        created: "orderByCreated",
        modified: "orderByModified",
      },
      changeStatusForm: {
        id: null,
        isVisible: false,
        isActive: false,
        isDisabled: false,
      },
      revenueCodes: [],
      selectedDetails: "",
      isDataLoading: false,
      initialDataLoading: false,
      isViewing: false,
      isRevenueCodeModalVisible: false,
      isDiscardModalVisible: false,
      auditTrails: {
        id: null,
        isVisible: false,
      },
      columns: [
        {
          name: "codeType",
          align: "left",
          text: "Code Types",
          field: "codeType",
          sortable: true,
        },
        {
          name: "code",
          align: "left",
          text: "Code",
          field: "code",
          sortable: true,
        },
        {
          name: "codeDescription",
          align: "left",
          text: "Code Description",
          field: "description",
          sortable: true,
        },
        {
          name: "helpInfo",
          align: "left",
          text: "Help Info",
          field: "helpInfo",
          sortable: true,
        },
        {
          name: "GLCode",
          align: "left",
          text: "GL Code",
          field: "glCode",
          sortable: true,
        },
        {
          name: "validityStartDate",
          align: "left",
          text: "Effective Date",
          field: "validityStartDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "validityEndDate",
          align: "left",
          text: "Termination Date",
          field: "validityEndDate",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "created",
          align: "left",
          text: "Creation Date",
          field: "created",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "modified",
          align: "left",
          text: "Modified Date",
          field: "lastModified",
          sortingLabel: {
            asc: "Oldest to Newest",
            desc: "Newest to Oldest",
          },
          sortable: true,
        },
        {
          name: "status",
          align: "center",
          text: "Status",
          field: "status",
          sortable: true,
          isStatusColumn: true,
        },
        {
          name: "action",
          text: "Action",
          field: "action",
          align: "center",
        },
      ],
      searchFilter: {
        columns: 4,
        model: RevenueCode,
        fields: [
          {
            label: "Code Type",
            name: "codeType",
            type: "select",
            isEnum: true,
            singleSelect: true,
            selectOptions: [],
            click: () => this.getCodeTypes(),
          },
          {
            label: "Code",
            name: "code",
            type: "select",
            singleSelect: true,
            selectOptions: [],
            click: () => this.getCodes(),
          },
          {
            label: "GL Code",
            name: "glCode",
            type: "text",
            maxlength: 4,
            colSpan: 6,
          },
          {
            label: "Effective Date",
            name: "validityStartDate",
            type: "date",
            format: "MM/DD/YYYY",
            isInline: true,
          },
          {
            label: "Termination Date",
            name: "validityEndDate",
            type: "date",
            format: "MM/DD/YYYY",
            isInline: true,
          },
        ],
        isLoading: {
          codeTypes: false,
          codes: false,
        },
        forceLoadOptions: {
          codeTypes: false,
          codes: false,
        },
      },
      limitData: [
        "codeType { enum label }",
        "code",
        "description",
        "helpInfo",
        "glCode",
        "isActive",
        "validityStartDate",
        "validityEndDate",
        "lastModified",
        "created",
        "totalCount",
      ],
      totalCount: 0,
    };
  },
  computed: {
    filteredRevenueCodes() {
      try {
        const data = this.revenueCodes.map(
          ({
            id,
            codeType,
            code,
            description,
            helpInfo,
            glCode,
            isActive,
            validityStartDate,
            validityEndDate,
            lastModified,
            created,
          }) => ({
            id,
            codeType: codeType?.label || null,
            code,
            description,
            helpInfo,
            glCode,
            isActive,
            status: isActive ? "Active" : "Deactivated",
            validityStartDate: validityStartDate
              ? this.$options.filters.getTimeDate(
                  validityStartDate,
                  "MM/DD/YYYY"
                ).zone
              : null,
            validityEndDate: validityEndDate
              ? this.$options.filters.getTimeDate(validityEndDate, "MM/DD/YYYY")
                  .zone
              : null,
            lastModified: this.$options.filters.parseDateFromString(
              lastModified,
              "MM/DD/YYYY"
            ),
            created: this.$options.filters.parseDateFromString(
              created,
              "MM/DD/YYYY"
            ),
          })
        );
        return data;
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
  },
  methods: {
    async getCodeTypes() {
      const { isLoading, fields, forceLoadOptions } = this.searchFilter;
      try {
        if (!isLoading.codeTypes && (!fields[0].selectOptions.length || forceLoadOptions.codeTypes)) {
          isLoading.codeTypes = true;
          fields[0].selectOptions = [
            {
              label: "Fetching Code Types...",
              disabled: true,
            },
          ];
          const { enumValues } = await actions.getEnumValues(
            "VbsRevenueCodeTypeEnum"
          );
          isLoading.codeTypes = false;
          if (enumValues) {
            forceLoadOptions.codeTypes = false;
            fields[0].selectOptions = enumValues.map(({ name }) => ({
              label: name
                .split("_")
                .map((i) => capitalize(i))
                .join(" "),
              value: name,
            }));
            return;
          }
          this.showNotifyMessage({
            type: "danger",
            message: "Unable to fetch data.",
          });
        }
      } catch (err) {
        isLoading.codeTypes = false;
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async getCodes() {
      const { isLoading, fields, forceLoadOptions } = this.searchFilter;
      try {
        if (!isLoading.codes && (!fields[1].selectOptions.length || forceLoadOptions.codes)) {
          isLoading.codes = true;
          fields[1].selectOptions = [
            {
              label: "Fetching Codes...",
              disabled: true,
            },
          ];
          const results = await actions.getCodes({
            limitData: ["codeName"],
            variables: {
              property: {
                archive: {
                  type: "Boolean!",
                  value: true,
                },
              },
            },
          });
          isLoading.codes = false;
          if (results) {
            forceLoadOptions.codes = false;
            fields[1].selectOptions = results.map(({ codeName }) => ({
              label: codeName,
              value: codeName,
            }));
            return;
          }
          this.showNotifyMessage({
            type: "danger",
            message: "Unable to fetch data.",
          });
        }
      } catch (err) {
        isLoading.codes = false;
        this.showNotifyMessage({
          type: "danger",
          message: "Unable to fetch data.",
        });
      }
    },
    async fetch() {
      try {
        this.resetDataTableOptions();
        await this.$refs.searchFilter.filterSearchModel(10, null);
      } catch (e) {
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    async fetchRevenueCodeDetails(id) {
      this.isLoading = true;
      const result = await actions.getRevenueCodeDetails({
        variables: {
          property: {
            id: {
              type: "UUID!",
              value: id,
            },
          },
        },
        limitData: [
          "id",
          "codeType { enum label }",
          "code",
          "description",
          "glCode",
          "helpInfo",
          "validityStartDate",
          "validityEndDate",
        ],
      });
      if (result) {
        const { codeType, validityStartDate, validityEndDate } = result;
        this.selectedDetails = JSON.stringify({
          ...result,
          codeType: codeType?.enum || "",
          validityStartDate: validityStartDate
            ? this.$options.filters.getTimeDate(validityStartDate, "MM/DD/YYYY")
                .zone
            : "",
          validityEndDate: validityEndDate
            ? this.$options.filters.getTimeDate(validityEndDate, "MM/DD/YYYY")
                .zone
            : "",
        });
        this.isLoading = false;
      } else {
        this.isLoading = false;
        this.handleFormModalVisibility(false, false);
        this.showNotifyMessage({
          message: "Problem has occurred while fetching data.",
          type: "danger",
        });
      }
    },
    loading(bool) {
      this.isDataLoading = bool;
    },
    async searchResults(result) {
      const { forceLoadOptions } = this.searchFilter;
      forceLoadOptions.codeTypes = true;
      forceLoadOptions.codes = true;
      if (result.length) {
        this.totalCount = result[0].totalCount;
      } else {
        this.totalCount = 0;
      }
      this.revenueCodes = result;
    },
    handleFormModalVisibility(isVisible, isViewing = false) {
      this.isRevenueCodeModalVisible = isVisible;
      this.isViewing = isViewing;
      if (!isVisible) {
        this.selectedDetails = "";
      }
    },
    toggleAddRevenueCodeModal() {
      this.handleFormModalVisibility(true, false);
    },
    toggleEditRevenueCodeModal(id) {
      this.handleFormModalVisibility(true, true);
      this.fetchRevenueCodeDetails(id);
    },
    handleDiscardModalVisibility(isVisible) {
      this.isDiscardModalVisible = isVisible;
    },
    confirmDiscardChanges() {
      this.handleDiscardModalVisibility(false);
      this.handleFormModalVisibility(false, false);
    },
    toggleChangeStatus(id, isActive) {
      this.handleChangeStatusVisibility(true, id, isActive);
    },
    handleChangeStatusVisibility(value, id = null, isActive = false) {
      const { isVisible } = this.changeStatusForm;
      if (isVisible !== value) {
        this.changeStatusForm = {
          ...this.changeStatusForm,
          isVisible: value,
          id,
          isActive,
        };
      }
    },
    async getChangeStatusAction(params) {
      const { isActive } = this.changeStatusForm;
      if (isActive) {
        return await actions.deactivateRevenueCode(params);
      }
      return await actions.activateRevenueCode(params);
    },
    async onSubmitChangeStatus() {
      const { isActive, isDisabled, id } = this.changeStatusForm;
      if (!isDisabled) {
        this.changeStatusForm.isDisabled = true;
        const params = {
          id: {
            type: "UUID!",
            value: id,
            unincludeToFields: true,
          },
          limitData: ["success", "errors"],
          shouldNotReturnId: true,
        };
        try {
          const { success, errors } = await this.getChangeStatusAction(params);
          if (!success || errors.length > 0) {
            const errorMesssage =
              errors.length > 0
                ? errors[0]
                : "Problem has occurred while updating Revenue Code status.";
            throw errorMesssage;
          }
          this.showNotifyMessage({
            message: `Revenue Code ${isActive ? "deactivated" : "activated"}.`,
            type: "success",
          });
          this.changeStatusForm = {
            id: null,
            isVisible: false,
            isActive: false,
            isDisabled: false,
          };
          this.fetch();
        } catch (error) {
          this.showNotifyMessage({
            message: error,
            type: "danger",
          });
          this.changeStatusForm.isDisabled = false;
        }
      }
    },
    resetDataTableOptions() {
      const { fields, forceLoadOptions } = this.searchFilter;
      forceLoadOptions.codeTypes = false;
      forceLoadOptions.codes = false;
      fields[0].selectOptions = [];
      fields[1].selectOptions = [];
      this.$refs.dataTable.resetDataTableOptions();
    },
    toggleAuditTrail(isVisible, id = null) {
      this.auditTrails = {
        id,
        isVisible,
      };
    },
  },
  mounted() {
    this.$nextTick(async () => {
      this.initialDataLoading = true;
      await this.fetch();
      this.initialDataLoading = false;
    });
  },
};
